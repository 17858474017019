import React from 'react'
import Header from './Header'
import Footer from "./Footer"
import { Dimmer } from 'semantic-ui-react'
import "semantic-ui-css/semantic.min.css"
import "../../v1/layout.css"

const Layout = ({ children }) => {
    return (

        <>
            <Dimmer.Dimmable>
                <Header />
                <main>
                    {children}
                </main>
                <Footer />
            </Dimmer.Dimmable>
        </>
    )
}

export default Layout
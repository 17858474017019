import React, { useEffect } from 'react'
import { List, Icon, Grid } from "semantic-ui-react";
import "./Footer.css";
import footerlogo from "../../../images/mickey-tree-logo.png";
import {
  useLocation,
} from "react-router-dom";
import { Link } from 'gatsby';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import styled from 'styled-components';
import { media } from "../../../../mq"

const FooterWrapper = styled.footer`
  border-top: 1px solid #6d6e708f;
  width: 100vw;
  height: fit-content;
  padding: 30px;
  font-family: 'BrownLLSub-Regular';
  font-weight: normal;
    .footer-inner-wrapper{

      .column{
        margin: 0 auto 0 auto;
      }
      .footer-logo{
        width: 50px;
        height: auto;
      }
      .socials{
        display: flex;
        align-items: baseline;
      }
      .ui.list.socials > .item > a >i{
        color: black ;
        transition: .2s ease-in-out;
        &:hover{
          color: #f1592a
        }
      }
      .ui.list > .item > a{
        color: black ;
        transition: .2s ease-in-out;
        &:hover{
          color: #f1592a
        }
      }
    }

  

`

const Footer = () => {
  const date = new Date()
  const thisYear = date.getFullYear()
  const wd = useWindowDimensions()

  return (
    <FooterWrapper>
      {
        wd && wd?.width &&
        <Grid textAlign={wd?.width >= 869 ? "left" : "center"} className='footer-inner-wrapper' stackable style={{ flexDirection: wd?.width <= 869 ? "column" : "row" }}>
          {
            wd?.width >= 869 &&
            <Grid.Column width={2}>
              <Link to="/" >
                <img className="footer-logo" src={footerlogo} />
              </Link>
            </Grid.Column>
          }
          <Grid.Column width={wd?.width >= 869 ? 3 : 16}>
            <p class="footer-sub-heading">SOCIALS</p>
            <List className='socials' style={{ justifyContent: wd?.width >= 869 ? "flex-start" : "center" }}>
              <List.Item>
                <a href="https://www.instagram.com/mickeytrading/?hl=en" target="_blank">
                  <i aria-hidden="true" class="instagram icon"></i>
                </a>
              </List.Item>
              <List.Item>
                <a href="https://twitter.com/mickeytrading?lang=en" target="_blank">
                  <i aria-hidden="true" class="twitter icon"></i>
                </a>
              </List.Item>
              <List.Item>
                <a href="https://www.linkedin.com/company/mickeygroup" target="_blank">
                  <i aria-hidden="true" class="linkedin icon"></i>
                </a>
              </List.Item>
              <List.Item>
                <a href="https://www.facebook.com/mickeygroup/" target="_blank">
                  <i aria-hidden="true" class="facebook icon"></i>
                </a>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={wd?.width >= 869 ? 2 : 16}>
            <List>
              <p class="footer-sub-heading">LEGAL</p>
              <List.Item>
                <Link to="/privacy-policy">
                  Privacy
                </Link>
              </List.Item>
              <List.Item>
                <Link to="/user-agreement">
                  Legal
                </Link>
              </List.Item>
              <List.Item>
                <Link to="/support">
                  Support
                </Link>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={wd?.width >= 869 ? 2 : 16}>
            <List>
              <p class="footer-sub-heading">News</p>
              <List.Item>
                <Link to="/press">
                  Press
                </Link>
              </List.Item>
              <List.Item>
                <Link to="/news">
                  News
                </Link>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={wd?.width >= 869 ? 3 : 16}>
            <List>
              <p class="footer-sub-heading">Contact</p>

              {
                window !== undefined && window?.location && window.location?.pathname === "/contractor-direct" ?
                  (
                    <>
                      <List.Item>
                        (503)-438-6209
                      </List.Item>
                      <List.Item>
                        <a href="mailto:ncrouse@mickeytrading.com">
                          ncrouse@mickeytrading.com
                        </a>
                      </List.Item>
                    </>
                  )
                  :
                  (
                    <>
                      <List.Item>
                        (510) 800-6254
                      </List.Item>
                      <List.Item>
                        <a href="mailto:info@mickeytrading.com">
                          info@mickeytrading.com
                        </a>
                      </List.Item>
                    </>
                  )

              }
            </List>
          </Grid.Column>
          <Grid.Column width={wd?.width >= 869 ? 3 : 16}>
            <List>
              <List.Item className="copyright">Mickey © {thisYear}</List.Item>
            </List>
          </Grid.Column>
        </Grid>
      }
    </FooterWrapper>
  )
}

export default Footer
import React, { useState } from 'react'
import MickeyLogoBlack from "../../../images/mickey-tree-logo-words.png"
import {
  Grid,
  Menu,
  Button,
  Dimmer,
  List,
  Dropdown,
} from "semantic-ui-react";
import { Header as SemanticHeader } from "semantic-ui-react"
import "./Header.css";
import { Link, graphql, useStaticQuery } from 'gatsby';
import { CaretDown, NewspaperClipping, CalendarCheck, Article, X } from 'phosphor-react';
import styled from 'styled-components';
import { motion } from "framer-motion";
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import { Squash } from 'hamburger-react';
import { useNavigate } from "react-router-dom";
import { Circle, TextAa } from "phosphor-react"


const HeaderWrapper = styled(motion.header)`
  width: 100vw;
  height: 100px;
  border: none;
  box-shadow: none;
  z-index: 100;
  background-color: white;
  .nav-link{
        transition: 0.2s ease-in-out;
        color: black;
        &:hover{
          color: #f1592a;
        }
      }
  .ui.menu.header-nav{
    padding: 0 30px 0 30px;
    border: inherit;
    box-shadow: inherit;
    margin: 0 !important;
    font-family: "Brown" !important;
    font-size: 14px;
    .logo-container{
      padding-right: 0;
      padding-left: 0;
    }
    .item{
      border: inherit;
      box-shadow: inherit;
      i{
        display: none;
      }

      &:before{
        display: none;
      }
    }
  }
  .ui.button.homepage-button{
    font-size: .75rem;
    background-color: white;
    border: 1px solid #f1592a;
    color: #f1592a;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s ease-in-out;
    &:hover{
      background-color: #f1592a;
      color: white;
    }
  }

  .industry-link{
    color: black;
    transition: .2s ease-in-out;
    &:hover{
      color: #f1592a; 
    }
  }
  #mobile-menu-overlay{
    background-color: rgba(255,255,255,1) !important;
    .content{
      height: 100%;
      width: 100%;
      .mobile-menu-inner-wrapper{
        height: inherit;
        width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        .ui.list{
         font-family: "BrownLLSub-Regular";
         color: black;
         .item{
          margin-bottom: 2.5rem;
          font-size: 25px;
         }
        }
    }
    }
  }
  .menu.transition.visible{
    min-height: 40vh;
  }
`

const Header = () => {
  const data = useStaticQuery(graphql`
  query HeaderIndustriesQuery {
    allWpIndustry {
      edges {
        node {
          title
          slug
          industry {
            customOnlineMarketplaceCopy
            automatedFulfillmentCopy
            orderManagementCopy
            security
            liveTracking
            automatedLogistics
            automatedSeo
            easyInvoicing
            lowestPrice
          }
        }
      }
    }
  }
`)

  const location = typeof window !== 'undefined' ? window?.location : ""
  const [animationComplete, setAnimationComplete] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [resourceDropdownOpen, setResourceDropdownOpen] = useState(false)
  const [industriesDropdownOpen, setIndustriesDropdownOpen] = useState(false)
  const [mobileResourceDropdownOpen, setMobileResourceDropdownOpen] = useState(false)
  const [mobileIndustriesDropdownOpen, setMobileIndustriesDropdownOpen] = useState(false)
  const wd = useWindowDimensions()

  function onAnimationComplete() {
    setAnimationComplete(true);
  }

  const variants = {
    open: { height: "250px", },
    closed: { height: "0px", }
  }

  const handleResourceDropdown = () => {
    if (industriesDropdownOpen) {
      setIndustriesDropdownOpen(false)
      setResourceDropdownOpen(true)
    }
    if (!industriesDropdownOpen) {
      setResourceDropdownOpen(true)
    }
    if (resourceDropdownOpen && !industriesDropdownOpen) {
      setResourceDropdownOpen(false)
    }
  }
  const handleIndustryDropdown = () => {
    if (resourceDropdownOpen) {
      setResourceDropdownOpen(false)
      setIndustriesDropdownOpen(true)
    }
    if (!resourceDropdownOpen) {
      setIndustriesDropdownOpen(true)
    }
    if (!resourceDropdownOpen && industriesDropdownOpen) {
      setIndustriesDropdownOpen(false)
    }
  }



  return (
    <>


      <HeaderWrapper
        initial={{ height: "100px" }}
        animate={(resourceDropdownOpen || industriesDropdownOpen) ? "open" : "closed"}
        exit={{ height: "100px" }}
        variants={{
          open: { height: wd.width <= 768 ? "400px" : "fit-content" },
          closed: { height: "100px" },
        }}
        transition={{ duration: 0.3 }}
        onAnimationComplete={onAnimationComplete}
      >
        <Menu className='header-nav' style={{ height: "100px", border: "none", boxShadow: "none" }}>
          <Menu.Item className='logo-container' style={{ border: "none", boxShadow: "none" }}>
            <Link to="/">
              <img src={MickeyLogoBlack} style={{ width: "100px", height: "auto", margin: "0" }} />
            </Link>
          </Menu.Item>
          {
            wd.width > 768 &&
            <Menu.Menu position='right' style={{ border: "none", boxShadow: "none" }}>
              <Menu.Item>
                <Link className="nav-link" to='/about'>
                  About
                </Link>
              </Menu.Item>
              <Menu.Item>
                <div
                  onMouseEnter={() => handleIndustryDropdown()}
                  onClick={() => handleIndustryDropdown()}

                >
                  <p className='nav-link' style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                    Industries
                    <CaretDown style={{ marginLeft: ".5rem", transition: ".2s ease-in-out", transform: industriesDropdownOpen ? "rotate(180deg)" : "" }} />
                  </p>
                </div>
              </Menu.Item>
              <Menu.Item>
                <Link className="nav-link" to={`/products`}>
                  Products
                </Link>
              </Menu.Item>
              <Menu.Item>
                <div

                  onMouseEnter={() => handleResourceDropdown()}
                  onClick={() => handleResourceDropdown()}
                >
                  <p className='nav-link' style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                    Resources
                    <CaretDown style={{ marginLeft: ".5rem", transition: ".2s ease-in-out", transform: resourceDropdownOpen ? "rotate(180deg)" : "" }} />
                  </p>
                </div>
              </Menu.Item>
              <Menu.Item position={wd.width <= 768 ? "right" : ""}>
                <Button className="homepage-button" href="/request-demo/?&origin=Header">
                  Request Demo
                </Button>
              </Menu.Item>

            </Menu.Menu>
          }
          {wd.width <= 768 &&
            <Menu.Item position='right'>
              <Squash
                toggled={openMobileMenu}
                toggle={setOpenMobileMenu}
              />
            </Menu.Item>
          }
        </Menu>

        {
          animationComplete &&
          <Grid
            onMouseLeave={() => {
              if (resourceDropdownOpen && !industriesDropdownOpen) {
                handleResourceDropdown()
              }
              if (industriesDropdownOpen && !resourceDropdownOpen) {
                handleIndustryDropdown()
              }
            }}
            className={resourceDropdownOpen && animationComplete && "menu-resource-dropdown"}
            stackable
            width={16}
            style={{
              height: "fit-content",
              minHeight: "150px",
              backgroundColor: "white",
              justifyContent: "space-evenly",
            }}
          >
            {resourceDropdownOpen && animationComplete &&
              <>
                <Grid.Column
                  width={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                    <NewspaperClipping size={25} color="#f1592a" style={{ marginRight: "1rem" }} />
                    <Link className='nav-link' to='/news' style={{ fontSize: "18px" }}>
                      News
                    </Link>
                  </div>
                  <p style={{ fontFamily: "BrownLLSub-Regular", lineHeight: "1.5rem" }}>
                    Mickey in the news
                  </p>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                    <Article size={25} color="#f1592a" style={{ marginRight: "1rem" }} />
                    <Link className='nav-link' to='/press' style={{ fontSize: "18px" }}>
                      Press
                    </Link>
                  </div>
                  <p style={{ fontFamily: "BrownLLSub-Regular", lineHeight: "1.5rem" }}>
                    Up-to-date press releases from Mickey
                  </p>
                </Grid.Column>
              </>
            }

            {industriesDropdownOpen && animationComplete &&
              <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "1rem" }}>
                <ul style={{ display: "flex", flexWrap: "wrap", width: "70%", paddingLeft: "8vw" }}>
                  {
                    data?.allWpIndustry?.edges.map(industry => {
                      const title = industry.node.title
                      const slug = industry.node.slug
                      return (
                        <li style={{ display: "flex", flex: "0 1 33%", fontSize: "16px" }}>
                          <Link className='industry-link' to={`/industries/${slug}/`}>
                            <Circle
                              size={12}
                              style={{
                                marginRight: ".5rem",
                                marginTop: "3px",
                                color: "red",
                              }}
                            />
                            {title}
                          </Link>
                        </li>
                      )
                    })
                  }
                  <li style={{ display: "flex", flex: "0 1 33%", fontSize: "16px" }}>
                    <Link className='industry-link' to="/mickey-energy">
                      <Circle
                        size={12}
                        style={{
                          marginRight: ".5rem",
                          marginTop: "3px",
                          color: "red",
                        }}
                      />
                      Energy
                    </Link>
                  </li>
                </ul>
              </div>

            }
          </Grid>
        }

        <Dimmer
          id="mobile-menu-overlay"
          inverted
          blurring
          active={openMobileMenu}
          onClickOutside={() => setOpenMobileMenu(!openMobileMenu)}
          style={{
            position: "fixed",
            height: "100vh",
            width: "100vw"
          }}
        >
          <Button
            onClick={() => setOpenMobileMenu(!openMobileMenu)}
            style={{
              position: "absolute",
              top: "2rem",
              right: "calc(1.14285714em + 30px)",
              padding: "0",
              backgroundColor: "transparent",
              border: "none"
            }}
          >
            <X
              size={32}
              color="#2b2727"

            />
          </Button>
          <div className='mobile-menu-inner-wrapper'>
            <List>
              <List.Item>
                <Link to='/about' className='nav-link'>
                  About
                </Link>
              </List.Item>
              <List.Item
                className='nav-link'
                onClick={() => setMobileIndustriesDropdownOpen(!mobileIndustriesDropdownOpen)}
                style={{ cursor: "pointer" }}
              >
                Industries
                <motion.div
                  animate={mobileIndustriesDropdownOpen ? "open" : "closed"}
                  variants={variants}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  {mobileIndustriesDropdownOpen &&
                    <Dropdown
                      placeholder='Select Industry'
                      fluid
                      selection
                    >
                      <Dropdown.Menu>
                        {
                          data?.allWpIndustry?.edges.map(industry => {
                            const title = industry.node.title
                            const slug = industry.node.slug
                            return (
                              <Dropdown.Item href={`/industries/${slug}`}>
                                {title}
                              </Dropdown.Item>
                            )
                          })
                        }

                      </Dropdown.Menu>

                    </Dropdown>

                  }
                </motion.div>
              </List.Item>
              <List.Item>
                <Link to='/products' className='nav-link'>
                  Products
                </Link>
              </List.Item>
              <List.Item
                className='nav-link'
                onClick={() => setMobileResourceDropdownOpen(!mobileResourceDropdownOpen)}
                style={{ cursor: "pointer" }}
              >
                Resources
                <motion.div
                  animate={mobileResourceDropdownOpen ? "open" : "closed"}
                  variants={variants}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  {mobileResourceDropdownOpen &&
                    <List
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingLeft: "0",
                        paddingTop: "1rem"
                      }}
                    >
                      <List.Item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1.5rem"
                        }}
                      >
                        <NewspaperClipping size={25} color="#f1592a" style={{ marginRight: "1rem" }} />
                        <Link className='nav-link' to='/news' style={{ fontSize: "18px" }}>
                          News
                        </Link>
                      </List.Item>
                      <List.Item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1.5rem"
                        }}
                      >
                        <Article size={25} color="#f1592a" style={{ marginRight: "1rem" }} />
                        <Link className='nav-link' to='/press' style={{ fontSize: "18px" }}>
                          Press
                        </Link>
                      </List.Item>
                    </List>
                  }
                </motion.div>
              </List.Item>
              <List.Item
                className='nav-link'
              >
                <Button
                  className='homepage-button'
                  style={{
                    fontSize: "20px",
                  }}
                  href="/request-demo/?&origin=Header"
                  onClick={() => setOpenMobileMenu(!openMobileMenu)}
                >
                  Request Demo
                </Button>
              </List.Item>
            </List>
          </div>
        </Dimmer>
      </HeaderWrapper >
    </>
  )
}

export default Header